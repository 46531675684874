import './App.module.scss';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import HomePage from './pages/Dashboard/HomePage';
import 'dayjs/locale/vi';

import styles from './App.module.scss';
import ThankYouPage from './pages/Dashboard/ThankYouPage';
import ControlsPage from './pages/Dashboard/ControlsPage';
import MonitorPage from './pages/Dashboard/MonitorPage';

function App() {
  return (
      <div className={styles.moduleWrapper}>
        <ConfigProvider>
          <Router>
            <Switch>
              <Route path="/thank-you">
                <ThankYouPage/>
              </Route>
              <Route path="/controls">
                <ControlsPage/>
              </Route>
              <Route path="/monitor">
                <MonitorPage/>
              </Route>
              <Route path="/">
                <HomePage/>
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
  );
}

export default App;
