import {PublicLayout} from '../../components/layouts/PublicLayout';
import {useHistory} from 'react-router-dom';

import styles from './_page.module.scss';
import axios from 'axios';
import {message} from 'antd';

export default function HomePage() {
  const history = useHistory();

  return (
      <PublicLayout>
        <div className={styles.moduleWrapper}>
          <div className="centerContent">
            <div className={'first'}>YEAR END PARTY 2023</div>
            <div className={'second'}>meteor</div>
            <div className={'third'}>night</div>

            <div className="bottom">
              <div className={'fourth'}>
                Bạn có sẵn sàng đồng hành
                <br/>cùng MOHA "Vượt Sóng" 2024?

                <br/>
                MOHAと一緒に<br/>「波を乗り越え」ましょう！
              </div>

              <button className={'ready'}
                   style={{
                     outline: 'none',
                     border: 'none',
                     cursor: 'pointer',
                   }}
                   onClick={async () => {
                     const data = await axios.get('https://dev-api.yep.moha.team/api/configuration');
                     const isEnabledQRCode = data.data.data.isEnabledQRCode;

                     if (isEnabledQRCode) {
                       await axios.get('https://dev-api.yep.moha.team/api/custom/joinNow');
                       history.push('/thank-you');
                       message.success('Thank you for joining with MOHA!', 5);
                     } else {
                       message.warning('Please hold on for the special moment!', 10);
                     }
                   }}
              >READY</button>
            </div>
          </div>
        </div>
      </PublicLayout>
  );
}
