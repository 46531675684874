import React from 'react';
import styles from './PublicLayout.module.scss';

const logo = require('../../assets/images/logo.svg');

export function PublicLayout(props) {
  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <img style={{marginTop: '30px'}} width={'110px'} src={logo.default} alt="Logo"/>
          {props.children}
        </div>
      </div>
  );
}
