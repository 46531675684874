import {PublicLayout} from '../../components/layouts/PublicLayout';
import {useHistory} from 'react-router-dom';
import styles from './_page.module.scss';

export default function ThankYouPage() {
  const history = useHistory();

  return (
      <PublicLayout>
        <div className={styles.moduleWrapper}>
          <div className="centerContent">
            <div className="thankYou">THANK YOU</div>
          </div>
        </div>
      </PublicLayout>
  );
}
