import {Button, Form, message, Select} from 'antd';
import axios from 'axios';

async function delay(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export default function ControlsPage() {

  async function onUpdateForm(values) {
    await axios.put('https://dev-api.yep.moha.team/api/configuration', {
      data: {
        isEnabledQRCode: values.isEnabledQRCode,
      }
    });

    message.success('Update success!')
  }

  async function resetPercent() {
    await axios.get('https://dev-api.yep.moha.team/api/custom/reset');
    message.success('Reset success!');
  }

  async function handleIncreaseTwoPercent() {
    await axios.get('https://dev-api.yep.moha.team/api/custom/joinNow');
    message.success('Increase 2% success!');
  }

  async function changePercent(newPercent) {
    await axios.get('https://dev-api.yep.moha.team/api/custom/customPercent?percent=' + newPercent);
    message.success('Change percent success!');
  }

  return (
      <div style={{maxWidth: '1000px', margin: '0 auto'}}>
        <h1>Controls page for admin YEP</h1>

        <Form
          name="basic"
          layout={'vertical'}
          initialValues={{
            isEnabledQRCode: false,
          }}
          onFinish={onUpdateForm}
        >
          <Form.Item
              name={'isEnabledQRCode'} label={'Cho phép quét Click ready Code'}
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button htmlType={'submit'} block>Update</Button>
          </Form.Item>
        </Form>

        <hr/>

        <br/>

        <div className="ctas">
          <Button block onClick={resetPercent}>Reset thanh năng lượng</Button>

          <Button block onClick={handleIncreaseTwoPercent}>Tăng thêm 2% năng lượng (max 80%)</Button>

          <Button block onClick={async () => {
            await changePercent(80);
          }}>Tăng lên 80%</Button>

          <Button block onClick={async () => {
            await changePercent(100);
          }}>Tăng dần lên 100%</Button>
        </div>
      </div>
  );
}

