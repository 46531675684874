import styles from './_page.module.scss';
import logo from '../../assets/images/logo.svg';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Button} from 'antd';
import axios from 'axios';

const waitingBgSrc = require('../../assets/videos/bg.mp4');
const loadingBarSrc = require('../../assets/images/loadingBar.png');
const rotateSrc = require('../../assets/images/rotate.webp');

async function delay(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export default function MonitorPage() {
  const [statePercent, setStatePercent] = useState(0);
  const [stateLoading, setStateLoading] = useState('false');

  const [started, setStarted] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      const responseLatest = await axios.get('https://dev-api.yep.moha.team/api/configuration');
      const serverPercent = responseLatest.data.data.currentPercent;

      if (serverPercent === 100) {
        clearInterval(interval);

        for (let i = 0; i <= 20; i++) {
          setStatePercent(80 + i);
          await delay(0.1);
        }
      }

      setStateLoading(true);
      localStorage.setItem('isStateLoading', 'true');

      setStatePercent(serverPercent);
    }, 250);

    // Reset
    localStorage.setItem('isStateLoading', 'true');
    localStorage.setItem('currentPercent', '6');

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
      <div>
        <div className={'monitorPage ' + styles.moduleWrapper}>

          {!!started && (
            <Fragment>
              <div style={{marginTop: '50px', position: 'relative', zIndex: 2000, textAlign: 'center'}}>
                <img style={{}} width={'168px'} src={logo} alt="Logo"/>
              </div>

              <div className="relative">
                <div className={'video-background'}>
                  {(<video autoPlay={true} loop={true} controls={false} src={waitingBgSrc}></video>)}
                </div>

                {!!stateLoading && (
                    <div className={'loadingArea'} style={{
                      '--percent': `${statePercent}%`,
                    }}>
                      <div className="heading">
                        <div><strong>VƯỢT SÓNG 2024</strong></div>
                        <div>2024の波を乗り越える</div>
                      </div>

                      <div className="absolute">
                        <img className={'loadingBar'} src={loadingBarSrc} alt="Loading bar"/>
                        <img className={'rotate spin'} src={rotateSrc} alt="Rotate"/>
                        <div className={'indicator'}></div>
                        <div className="loadingText">Loading {Number(statePercent) <= 6 ? 0 : statePercent }%</div>
                      </div>
                    </div>
                )}
              </div>
            </Fragment>
          )}

          {!started && (
              <Button
                style={{
                  margin: '100px 100px',
                }}
                onClick={() => {
                setStarted(true);
                localStorage.setItem('currentPercent', '6');
              }}>Start now</Button>
          )}
        </div>

        <video style={{width: '1px', height: '1px'}} autoPlay={false} preload={'auto'} controls={false} src={waitingBgSrc}></video>
      </div>
  );
}
